import React from 'react';
import Helmet from 'react-helmet';

const SEO_DATA = {
  description: 'Blackships provides consultation to companies and invests in the future.',
  title: 'Blackships',
  url: 'https://blackships.net',
  author: 'Blackships Consultoria Lda',
  keywords: ['blackships', 'blackships portugal', 'blackships consulting', 'blackships investments', 'blackships IT', 'blackships real estate'],
  twitter: {
    img: 'https://raw.githubusercontent.com/jackveiga/blackships-website/master/src/images/twitter_image.png?token=AABUVLQ4H5R6FDP7QAIX5CC5V5UA2',
  },
  facebook: {
    img: 'https://raw.githubusercontent.com/jackveiga/blackships-website/master/src/images/og_image.png?token=AABUVLXAD53RYTDFPSDTGYS5V5T6I',
  },
};

const SEO = () => {
  return (
    <Helmet>
      <meta property="og:title" content={SEO_DATA.title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={SEO_DATA.url} />
      <meta property="og:image" content={SEO_DATA.facebook.img} />
      <meta property="og:description" content={SEO_DATA.description} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={SEO_DATA.title} />
      <meta name="twitter:description" content={SEO_DATA.description} />
      <meta name="twitter:domain" content={SEO_DATA.url} />
      <meta name="twitter:image:src" content={SEO_DATA.img} />

      <meta name="description" content={SEO_DATA.description} />
      <meta name="keywords" content={SEO_DATA.keywords.join(', ')} />
      <meta name="author" content={SEO_DATA.author} />
      <title>Blackships</title>
      <html lang="en" />
    </Helmet>
  );
};

export default SEO;
